import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ClientSideOnlyLazy = React.lazy(() =>
  import("./webinarCall")
)

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
  return(
  <Layout>
    <SEO title="Home" />
    <h1>Bienvenido</h1>
    <p>Esta es la plataforma de Webinar de SmartBits.</p>
    {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ClientSideOnlyLazy />
        </React.Suspense>
      )}
    {/*
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link>
    */}
  </Layout>
  )
}

export default IndexPage
